// src/therapy.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { Form, Button } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from "react-router-dom"


// Set axios default settings to include cookies
axios.defaults.withCredentials = true;

function Messages({ guserSubscriptionPlan }) {
    const [messages, setMessages] = useState([]);
    const [newClientInput, setNewClientInput] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const scrollRef = useRef(null);
    const textAreaRef = useRef(null);
    const [sessionTime, setsessionTime] = useState(500);
    const [photourl, setPhotourl] = useState('logo.png');
    const [personaname, setPersonaName] = useState('Psyd');

    const maxLength = 500;
    const remainingChars = maxLength - newClientInput.length;
    const [summary, setSummary] = useState('');
    const [isSummaryVisible, setIsSummaryVisible] = useState(false); // Track visibility state
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const [isVisible, setIsVisible] = useState(true);
    const [isVisible2, setIsVisible2] = useState(true);
    const [messageCount, setMessageCount] = useState(0); // New state variable

    const minutesByPlan = [
        { plan: 'Free', minutes: 60 },
        { plan: 'Basic', minutes: 120 },
        { plan: 'Premium', minutes: 240 },
        { plan: 'Ultimate', minutes: 480 },
        { plan: 'Guest', minutes: 30 },
        { plan: 'Family', minutes: 480 }
    ];

    const openPDF = () => {
        // Retrieve sessionid from cookies
        const sessionid = Cookies.get('sessionid'); // Assuming 'sessionid' is stored in a cookie

        if (sessionid) {
            // Construct the URL for the new tab with sessionid as a query string
            const pdfUrl = `/api/viewpdf?sessionid=${sessionid}`;
            // Open the URL in a new tab
            window.open(pdfUrl, '_blank');
        } else {
            console.error('Session ID not found');
        }
    };
    const fetchMessages = async () => {
        try {
            const response = await axios.get('/api/therapy');
            const sessiontimeused = response.data.sessiontimeused;
            const plan = minutesByPlan.find(plan => plan.plan == guserSubscriptionPlan);
            const minutes = plan ? Number(plan.minutes) : 0; // Fallback to 0 if no plan is found
            const sessionTimeLeft = minutes - sessiontimeused;
            setsessionTime(sessionTimeLeft);
            const response2 = await axios.get('/api/sessioninfo');
            const sessioninfo = response2.data.sessioninfo;
            Cookie.set('sessioninfo', sessioninfo);
            setPhotourl(JSON.parse(Cookies.get('sessioninfo')).photo_url);
            setPersonaName(JSON.parse(Cookies.get('sessioninfo')).persona_name);
            const tempmessages = response.data.therapyhistory;
            const parsedMessages = tempmessages?.map(tempmessage => JSON.parse(tempmessage)) || [];
            setMessages(parsedMessages);
            setMessageCount(parsedMessages.length); // Set message count on initial fetch
            scrollToBottom(); // Scroll to the bottom after initial fetch
        } catch (error) {
            console.error('Error fetching messages', error);
        }
    };

    const fetchSummary = async () => {
        try {
            if (isSummaryVisible) {
                // If not empty, set summary to an empty string
                setSummary('');
                setIsSummaryVisible(false);
                setIsLoading(false); // Set loading to false after the operation
            } else {
                setIsLoading(true);
                const response = await axios.get('/api/generatesummary');
                const tempSummary = TextWithFormatting(response.data.summary);
                setSummary(tempSummary);
                setIsSummaryVisible(true); // Show the summary
            }
        } catch (error) {
            console.error('Error fetching summary', error);
        } finally {
            setIsLoading(false); // Always set loading to false after the request completes
        }

    };


    const handleSendInput = async () => {
        if (newClientInput.trim() === '') return;
        setIsProcessing(true); // Disable button and show processing state
        try {
            const temp = newClientInput;
            setNewClientInput('');
            const res = await axios.post('/api/therapy', { content: JSON.stringify({ "clientinput": temp }) });
            const sessiontimeused = res.data.sessiontimeused;
            const plan = minutesByPlan.find(plan => plan.plan == guserSubscriptionPlan);
            const minutes = plan ? Number(plan.minutes) : 0; // Fallback to 0 if no plan is found
            const sessionTimeLeft = minutes - sessiontimeused;
            setsessionTime(sessionTimeLeft);
            const sessionid = res.data.sessionid;
            Cookie.set('sessionid', sessionid);  // Store sessionid in cookies
            const response = await axios.get('/api/sessioninfo');
            const sessioninfo = response.data.sessioninfo;
            Cookie.set('sessioninfo', sessioninfo);
            setPhotourl(JSON.parse(Cookies.get('sessioninfo')).photo_url);
            setPersonaName(JSON.parse(Cookies.get('sessioninfo')).persona_name);
            const tempmessages = res.data.therapyhistory;
            const parsedMessages = tempmessages?.map(tempmessage => JSON.parse(tempmessage)) || [];
            setMessages(parsedMessages);
            setMessageCount(parsedMessages.length); // Update message count after sending
            scrollToBottom();
        } catch (error) {
            console.error('Error sending input', error);
        } finally {
            setIsProcessing(false); // Re-enable button
        }
    };

    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.focus();
            scrollRef.current.scrollTo({
                top: scrollRef.current.scrollHeight,
                behavior: "smooth", // This adds a smooth scroll effect
            });
            if (textAreaRef.current) {
                textAreaRef.current.focus();
                textAreaRef.current.scrollIntoView({
                    top: textAreaRef.current.scrollHeight,
                    behavior: "smooth", // This adds a smooth scroll effect
                    block: 'end',
                    inline: 'nearest'
                });
            }
        }
    };

    useEffect(() => {
        if (guserSubscriptionPlan != 'Premium') {
            setIsVisible(false);
        }
        if (guserSubscriptionPlan != 'Premium' && guserSubscriptionPlan != 'Basic') {
            setIsVisible2(false);
        }
        const cookieExists = Cookies.get('sessionid') !== undefined;
        if (cookieExists) {
            fetchMessages();
        }
    }, []);

    useEffect(() => {
        if (messageCount > 0) { // Check if there are messages
            scrollToBottom();
        }
    }, [messageCount]); // Trigger

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission on Enter key press
        }
    };
    const TextWithFormatting = (text) => {
        // Use regular expressions to add HTML tags for formatting
        const formattedText = text
            .replace(/Mental Health Issues Summary/g, '<strong>Mental Health Issues Summary</strong>')
            .replace(/Recommendations Summary/g, '<br><strong>Recommendations Summary</strong>');

        return (
            <div dangerouslySetInnerHTML={{ __html: formattedText }} />
        );
    };

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>My Session</h1>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-3 rounded">
                <div className="row">
                    <div className="col-md-8 px-0 mb-0"> {/* Added margin-bottom to create space between columns */}
                        {sessionTime != 500 && (
                            <div className="psyd-font-light">{sessionTime}&nbsp;Therapy minutes left this month</div>
                        )}
                        <div className="border border-3 psyd-primary-bg-color psyd-font-light text-center rounded-top">
                            Session with {personaname} &nbsp; &nbsp;
                            <img
                                                    src={`./img/${photourl}`}
                                                    style={{
                                                        width: '25px', // Adjust width as needed
                                                        height: '25px',
                                                        borderRadius: '50%', // Make image circular
                                                        margin: '0px', // Add space between images
                                                        cursor: 'pointer',
                                                    }}
                                                />
                        </div>
                        <div
                            className="px-1 mb-0 border border-top-0 border-3 rounded-left rounded-right pattern"
                            style={{ height: '600px', overflowY: 'scroll', scrollbarWidth: 'thin', borderColor: 'white' }} // Change border color to white
                            ref={scrollRef} tabIndex="-1"
                        >
                            <ul className="mx-0 px-0">
                                {messages.length === 0 && <p className="text-center psyd-chat-response">How can I help you today?</p>}
                                {messages?.map((record) => (
                                    <li className="psyd-chat" key={record.history_id}> {/* Added key to list item */}
                                        <div className="d-flex justify-content-end mb-2 mt-2">
                                            <div className="p-1 rounded psyd-chat-input text-right" style={{ maxWidth: '70%' }}>
                                                {record.input_text}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <div className="p-1 rounded psyd-chat-response" style={{ maxWidth: '70%' }}>
                                                <img
                                                    src={`./img/${photourl}`}
                                                    style={{
                                                        width: '25px', // Adjust width as needed
                                                        height: '25px',
                                                        borderRadius: '50%', // Make image circular
                                                        margin: '0px', // Add space between images
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                                {record.response_text}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="border border-0 psyd-primary-bg-color psyd-font-light text-center">
                            <>
                                {/* Text area and Send Button on the same row */}
                                <div className="d-flex align-items-center">
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={newClientInput}
                                        onChange={(e) => setNewClientInput(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                        placeholder="Type your message..."
                                        maxLength={maxLength}
                                        className="flex-grow-1"
                                        ref={textAreaRef}
                                    />
                                    <Button
                                        variant="primary"
                                        onClick={handleSendInput}
                                        className="ms-2"
                                        style={{ height: '75px' }}
                                        disabled={isProcessing || sessionTime <= 0}
                                    >
                                        {isProcessing ? 'Processing...' : 'Send Message'}
                                    </Button>
                                </div>
                                {
                                    (isVisible2 || isVisible) && (
                                        <div className="d-flex mt-2 justify-content-center">
                                            {isVisible2 && (
                                                <Link to="/agegroup">
                                                <Button
                                                variant="primary"
                                                className="ms-2"
                                            >
                                            Personalize
                                            </Button></Link>
                                        )}
                                            {isVisible && (
                                                <Button
                                                    variant="primary"
                                                    onClick={openPDF}
                                                    className="ms-2"
                                                >
                                                    Download PDF
                                                </Button>
                                            )}
                                            {isVisible && (
                                                <Button
                                                    variant="primary"
                                                    onClick={fetchSummary}
                                                    className="ms-2"
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? 'Please wait...' : (isSummaryVisible ? 'Hide Summary' : 'View Summary')}
                                                </Button>
                                            )}
                                        </div>
                                    )
                                }
                            </>
                        </div>
                        <div className="psyd-primary-bg-color psyd-font-light"> {/* Added margin-bottom to create space between columns */}
                            {remainingChars} characters remaining
                        </div>
                        <div className="px-2 rounded psyd-secondary-bg-color psyd-font">
                            {summary}
                        </div>
                    </div>
                    <div className="col-md-4 px-2 align-items-top">
                        <div>
                            &nbsp;
                            <div className="border border-3 rounded-top psyd-font-light text-center">
                                Tips for an effective session
                            </div>
                            <div className="px-1 border border-top-0 border-3 rounded-left rounded-right align-items-top">
                                <ListGroup className="rounded p-1">
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font rounded p-1 mb-1 bi bi-check-circle">
                                        <b>&nbsp;Be Open and Honest</b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Set Clear Goals</b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Regularly Engage</b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font  rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Try Rephrasing questions</b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Supplement with Human Therapy</b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Be Patient with AI </b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font  rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Combine with Self-Care</b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Remember, It's a Tool</b>
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Messages;
