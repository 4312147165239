import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Personas = ({ guserSubscriptionPlan }) => {
    const [refvalues, setRefValues] = useState([]);
    const [therapistpersonaID, setTherapistPersonaID] = useState(null);
    const navigate = useNavigate();


    const fetchTherapistPersona = async () => {
        try {
            const response = await axios.get('/api/alltherapistpersona');
            const tempRefValues = response.data.refvalues;
            const parsedRefValues = tempRefValues?.map(tempRefValue => JSON.parse(tempRefValue)) || [];
            console.error(parsedRefValues);
            setRefValues(parsedRefValues);
        } catch (error) {
            console.error('Error fetching therapist personas', error);
        }
    };

    const handleCardClick = (id, name, photourl) => {
        // Check if the card is already selected
        if (therapistpersonaID === id) {
            // If selected, deselect it
            setTherapistPersonaID(1);
            Cookie.set('therapistpersonaid', 1);
        } else {
            // If not selected, select it
            setTherapistPersonaID(id);
            Cookie.set('therapistpersonaid', id);
            Cookie.set('personaname', name);
            Cookie.set('photourl', photourl);
        }

        console.log("Selected therapist persona:", id);
    };

    useEffect(() => {
        if (guserSubscriptionPlan != 'Premium') {
            navigate('/consent');
        }
        const sessionInfoCookie = Cookies.get('sessioninfo');
        if (sessionInfoCookie) {
            const sessionInfo = JSON.parse(sessionInfoCookie);
            setTherapistPersonaID(sessionInfo.persona_id);
        } else {
            const cookieID = Cookie.get('therapistpersonaid');
            if (cookieID) {
                setTherapistPersonaID(cookieID);
            }
        }
        fetchTherapistPersona();
    }, []);

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
                        <h1>Therapist Personas</h1>
                    </div>
                </div>
                <div className="row px-1 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg rounded">
                    <div className="col p-1 rounded">
                        You can choose one of the following personas as your therapist.
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3">
                <Row className="justify-content-start">
                    {refvalues?.map((refvalue, index) => (
                        <div className="col">
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', textAlign: 'center' }}>
                                <img
                                    key={index}
                                    src={`./img/${refvalue.default_photo_url}`}
                                    alt={`Photourl ${index + 1}`}
                                    onClick={() => handleCardClick(refvalue.persona_id, refvalue.default_name, refvalue.default_photo_url)}
                                    style={{
                                        width: '100px', // Adjust width as needed
                                        height: '100px',
                                        borderRadius: '50%', // Make image circular
                                        margin: '5px', // Add space between images
                                        border: therapistpersonaID === refvalue.persona_id ? '2px solid blue' : 'none',
                                        cursor: 'pointer',
                                    }}
                                />
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '5px' }}>
                                    <span className='px-1 mt-0 mb-0 psyd-secondary-bg-color psyd-font shadow-lg rounded' style={{fontSize: 'small',textAlign: 'left',}}>
                                    {refvalue.persona_description}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </Row>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <Link to={'/therapistprofile'} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button>&lt; Therapist Profile</Button>
                </Link>
                <Link to={'/consent'} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button>Terms and Conditions &gt;</Button>
                </Link>
            </div>
        </div>
    );
}

export default Personas;
