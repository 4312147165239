import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom"


const ThemeSelection = ({ handleThemeChange }) => {
    const [themes, setThemes] = useState([]);
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const fetchThemes = async () => {
        try {
            const response = await axios.get('/api/allthemes');
            const tempThemes = response.data.themes;
            const parsedThemes = tempThemes?.map(tempTheme => JSON.parse(tempTheme)) || [];
            setThemes(parsedThemes);
        } catch (error) {
            console.error('Error fetching themes', error);
        }
    };

    useEffect(() => {
        fetchThemes();
    }, []);

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Themes</h1>
                    </div>
                </div>
                <div className="row px-1 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col p-1">
                        The various themes available on our online therapy platform provide you with a unique way to personalize your experience, allowing you to match the interface to your current mood. By selecting a theme that resonates with how you feel—whether calming, vibrant, or neutral—you can create a more conducive environment for your therapy sessions. This customization not only enhances comfort and engagement but also helps foster a sense of ownership over your mental health journey. Tailoring the visual elements to reflect your emotional state can make the therapeutic process more relatable and effective, ultimately contributing to a more positive and empowering experience on the platform.
                    </div>
                </div>
            </div>
            <div className="container px-1 mt-1 button-block d-flex justify-content-center align-items-center">
                <Button id="btn-save" onClick={() => handleThemeChange(themes[index].theme_name)}>
                    Select this Theme
                </Button>
            </div>
            <div className="container px-1 mt-3 psyd-primary-bg-color button-block">
                <Carousel interval={null} activeIndex={index} onSelect={handleSelect}>
                    {themes?.map((theme, index) => (
                        <Carousel.Item key={index} className="px-3 mt-3 position-relative">
                            <div className="row align-self-end">
                                <Carousel.Caption className="position-absolute bottom-0 start-50 translate-middle-x mb-3 text-center">
                                    <h1>{theme.theme_description}</h1>
                                </Carousel.Caption>
                                <p></p><p></p><p></p>
                            </div>
                            <div className="row align-self-end">

                                <div className="col-sm-12">
                                    <img
                                        className="mx-auto d-block w-100"
                                        src={`../img/${theme.thumbnail_name}`}
                                        alt={theme.theme_name}
                                    />
                                </div>
                            </div>
                        </Carousel.Item>

                    ))}
                </Carousel>
            </div>
        </div>
    );
}

export default ThemeSelection;
