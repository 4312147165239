import React, { useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"



const ProcessSubscription = ({ guserSubscriptionPlan, setSubscriptionPlan }) => {
  const location = useLocation();

  useEffect(() => {
    // Use URLSearchParams to parse the query string inside useEffect
    const query = new URLSearchParams(location.search);
    let subscriptionID = query.get('subscriptionID');
    let id = '';
    if (!subscriptionID) {
      id = query.get('id');
      subscriptionID = id;
    }
    let customerID = query.get('customerID');
    const state = query.get('state');
    const userid = Cookies.get("userid");

    // Prepare the post data
    const postData = {
      "subscriptionID": subscriptionID,
      "customerID": customerID,
    };

    // Make POST request to Flask backend
    axios.post('/api/processsubscription', postData)
      .then(response => {
        const usersubscriptioninfo = JSON.parse(response.data.usersubscriptioninfo);
        Cookies.set('usersubscriptioninfo', JSON.stringify(usersubscriptioninfo));
        setSubscriptionPlan();
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });
  }, []);

  return (
    <div id='homediv' className="container px-0">
      <div className="container px-0 psyd-secondary-bg-color psyd-font rounded">
        <div className="row mt-1">
          <div className="col p-3">
            Your subscription purchase was processed successfully. Please start a new session.
            {guserSubscriptionPlan == 'Basic' && (
              <div className="col-sm-12 p-1">
                Your plan includes provides 120 minutes of therapy time per month and 12 months of history.
                It also includes Customizable User Interface, Personalized Therapy, and Tailored Therapist Profile.
              </div>
            )}
            {guserSubscriptionPlan == 'Premium' && (
              <div className="col-sm-12 p-1">
                Your plan includes 240 minutes of therapy time per month with 24 months of history retention.
                It also includes Customizable User Interface, Personalized Therapy, Tailored Therapist Profile,
                Past Sessions as PDF, Session Summaries, Human Integration, and Tailored Therapist Personas.
              </div>
            )}
          </div>
          <br />
          <Link to={'/sessions'} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button>Start a Session</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProcessSubscription;
