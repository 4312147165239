import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
// Set axios default settings to include cookies
//axios.defaults.withCredentials = true;
import Cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const clientId = '142785160028-7b0j02vhkn0ttu0j14djh6okgtrcnm3a.apps.googleusercontent.com';

function GoogleAuth() {
  const navigate = useNavigate();
  const handleSuccess = async (response) => {
    const idToken = response.credential;
    console.error("id Token:", idToken);
    processToken(idToken);
  };
  const processToken = async (idToken) => {
    try {
      // Send the token to the backend
      const res = await axios.post('/api/google', { idToken: idToken });
      //const res = await fetch('/auth/google', {method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ idToken: idToken })})
      //const data = await res.json();
      console.error("User:", JSON.parse(res.data.user));
      console.error("userid:", JSON.parse(res.data.user).user_id)
      Cookie.set('userid', JSON.parse(res.data.user).user_id);  // Store sessionid in cookies
      console.error("Status:", res.data.message);
      // Handle user data (if needed)
      const userinfo = JSON.parse(res.data.user);
      console.log('userinfo:', userinfo);
      Cookie.set('userinfo', JSON.stringify(userinfo));
      const usersubscriptioninfo = JSON.parse(res.data.usersubscriptionjson);
      Cookie.set('usersubscriptioninfo', JSON.stringify(usersubscriptioninfo));

      navigate('/processlogin');
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleError = (error) => {
    // Handle errors
    console.error(error);
  };

  return (
    <div className="container d-flex justify-content-center" style={{height:'150px'}}>
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
        />
      </GoogleOAuthProvider>
    </div>
  );
}

export default GoogleAuth;
