import React, { useState, useEffect } from 'react';
import { Button, Form } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import {useNavigate } from 'react-router-dom';




const AgeGroup = ({guserSubscriptionPlan}) => {
    const [refvalues, setrefvalues] = useState([]);
    const [agegroupid, setAgeGroupID] = useState(1);
    const navigate = useNavigate();

    console.error(guserSubscriptionPlan);
    const fetchAgeGroups = async () => {
        try {
            const response = await axios.get('/api/allagegroups');
            const temprefvalues = response.data.refvalues;
            const parserefvalues = temprefvalues?.map(temprefvalue => JSON.parse(temprefvalue)) || [];
            setrefvalues(parserefvalues);
        } catch (error) {
            console.error('Error fetching age groups', error);
        }
    };
    // Function to handle radio button selection
    const handleRadioChange = (event) => {
        setAgeGroupID(event.target.value);
        console.log("Selected age group:", event.target.value);
        Cookie.set('agegroupid', event.target.value);
    };
    useEffect(() => {
        if (guserSubscriptionPlan == 'Guest' || guserSubscriptionPlan == 'Free') {
            navigate('/consent'); 
          }
        const sessioninfoCookie = Cookies.get('sessioninfo');
        let sessioninfo = {};
        if (sessioninfoCookie) {
            sessioninfo = JSON.parse(sessioninfoCookie);
            setAgeGroupID(sessioninfo.age_group_id);
            Cookie.set('agegroupid', sessioninfo.age_group_id);
        }else {Cookie.set('agegroupid',agegroupid);}
        fetchAgeGroups();
    }, []);

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4     rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
                        <h1>Age Groups</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font align-items-center rounded">
                    <div className="col-md-12 p-1 d-flex justify-content-center">
                        Age is a significant factor in determining health and the effectiveness of therapy and necessitates tailored therapeutic approaches. 
                    </div>
                </div>
            </div>
            <div className="container mt-3 d-flex justify-content-center align-items-top">
                <div className="col">
                    <div className="list-group p-0 d-flex justify-content-center align-items-top">
                        {refvalues?.map((refvalue, index) => (
                            <Form.Check
                                key={index}
                                type="radio"
                                id={`refvalue-${index}`}
                                label={
                                    <span style={{ marginLeft: '20px' }}>{refvalue.age_group}</span> // Add spacing here
                                }
                                value={refvalue.age_group_id}
                                name="refvalue" // Grouping the radio buttons
                                className="psyd-secondary-bg-color rounded mb-2"
                                onChange={handleRadioChange}
                                checked={agegroupid == refvalue.age_group_id}
                                style={{ height: '30px', display: 'flex', alignItems: 'center' }} // Adjust height and center alignment
                            />
                        ))}
                    </div>
                </div>
            </div>
            <Link to={'/relationshipstatus'} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button>Relationship Status &gt;</Button>
            </Link>

        </div>
    );
}

export default AgeGroup;
