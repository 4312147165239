import React, { useCallback, useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import { useLocation } from 'react-router-dom';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const stripePromise = loadStripe("pk_live_51QeiiDDFD5c2CJ7ilXIfNqPwrSynS7GWlCX21klLIlpT4JZraFMl8sQl3rpRqGKebYWknkJel72iZ2vuBy6Gi2C40012i8Sz3q");

const CheckoutForm = () => {
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session using Axios
    return axios.post("/api/create-checkout-session")
      .then((response) => response.data.clientSecret) // Extract the clientSecret from the response
      .catch((error) => {
        console.error("Error fetching client secret:", error);
        throw error; // You can handle the error here, for example, by setting some error state
      });
  }, []);

  const options = { fetchClientSecret };

  return (

    <div id="checkout" className="psyd-secondary-bg-color px-3 psyd-font text-center">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}
const Return = ({ guserSubscriptionPlan, setSubscriptionPlan }) => {
  const location = useLocation();
  useEffect(() => {
    // Use URLSearchParams to parse the query string inside useEffect
    const query = new URLSearchParams(location.search);
    let sessionid = query.get('session_id');
    const userid = Cookies.get("userid");

    // Prepare the post data
    const postData = {
      "sessionid": sessionid,
    };

    // Make POST request to Flask backend
    axios.post('/api/processstripesubscription', postData)
      .then(response => {
        const usersubscriptioninfo = JSON.parse(response.data.usersubscriptioninfo);
        Cookies.set('usersubscriptioninfo', JSON.stringify(usersubscriptioninfo));
        setSubscriptionPlan();
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });
  }, []);

  return (
    <div id='homediv' className="container px-0">
      <div className="container px-0 psyd-secondary-bg-color psyd-font rounded">
        <div className="row mt-1">
          <div className="col p-3">
            Your subscription purchase was processed successfully.
            {guserSubscriptionPlan == 'Basic' && (
              <div className="col-sm-12 p-1">
                Your plan includes provides <b>120 minutes</b> of therapy time per month and 12 months of history.
                It also includes <b>Customizable User Interface, Personalized Therapy, and Tailored Therapist Profile</b>.
              </div>
            )}
            {guserSubscriptionPlan == 'Premium' && (
              <div className="col-sm-12 p-1">
                Your plan includes <b>240 minutes</b> of therapy time per month with 24 months of history retention.
                It also includes <b>Customizable User Interface, Personalized Therapy, Tailored Therapist Profile,
                Past Sessions as PDF, Session Summaries, Human Integration, and Tailored Therapist Personas</b>.
              </div>
            )}
          </div>
          <br />
        </div>
      </div>
      <Link to={'/sessions'} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button>Start a Session</Button>
      </Link>

    </div>
  )
}

export { CheckoutForm, Return };