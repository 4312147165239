import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Container } from 'react-bootstrap';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import {useNavigate } from 'react-router-dom';


const Share = ({guserSubscriptionPlan}) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        user_id: '',
        shared_with_email: '',
        shared_with_user_id: '',
        sharing_authorization_code: ''
    });

    // Handle form field change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (guserSubscriptionPlan != 'Premium') {
            navigate('/'); 
          }
        const userCookie = Cookies.get('userinfo');
        let userinfo = {};
        if (userCookie) {
            userinfo = JSON.parse(userCookie);
            setFormData(userinfo);  // Set form data from the fetched user
        }
        setErrorMessage('');
        const userCookieExists = Cookies.get('userid') !== undefined;
        if (userCookieExists) {
            fetchMessages();
        } 
    }, []);

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            if (formData.shared_with_email && formData.sharing_authorization_code && formData.sharing_authorization_code !== 'None') {
                axios.put(`/api/share`, formData)
                    .then((response) => {
                        if (response.data.exists) {
                            formData.shared_with_user_id = response.data.sharedwithuserid;
                            const userinfo = JSON.parse(response.data.user);
                            console.log('userinfo:', userinfo);
                            Cookie.set('userinfo', JSON.stringify(userinfo));            
                            setErrorMessage('Integrated successfully.');
                            fetchMessages();
                        } else {
                            setErrorMessage('The Email and Integration Authorization Code did not match');
                        }
                    })
                    .catch((error) => {
                        console.error('Error updating data:', error);
                    });
            } else {
                setErrorMessage('Please provide valid values for Integrated with User email and Integration Authorization Code');
            }
        } catch (error) {
            setErrorMessage(error);
        }
    };
    const openPDF = (sessionid) => {
        if (sessionid) {
            // Construct the URL for the new tab with sessionid as a query string
            const pdfUrl = `/api/viewpdf?sessionid=${sessionid}`;
            // Open the URL in a new tab
            window.open(pdfUrl, '_blank');
        } else {
            console.error('Session ID not found');
        }
    };
    const convertUtcToLocal = (utcDateString) => {
        const utcDate = new Date(utcDateString);
        return utcDate.toLocaleString(); // or use toLocaleTimeString() for just the time
    };
    const fetchMessages = async () => {
        try {
            const response = await axios.get('/api/sharedsessions');
            //const sessionid = response.data.sessionid;
            //Cookie.set('sessionid', sessionid.sessionid);  // Store sessionid in cookies
            const tempmessages = response.data.therapyhistory;
            const parsedMessages = tempmessages?.map(tempmessage => JSON.parse(tempmessage)) || [];
            console.error(parsedMessages);
            if (parsedMessages.length === 0) {
            }
            else { setMessages(parsedMessages); }
        } catch (error) {
            console.error('Error fetching messages', error);
        }
    };
    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Human Integration</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                        We understand that sometimes sharing your therapy sessions with a trusted individual, such as your human therapist, parent, or guardian, can be beneficial to your mental health journey. On our AI-powered online therapy platform, you can easily share your sessions with a registered user of the platform. In order to do so, you will need to obtain an **Integration Authorization Code** from the person you wish to share your sessions with. This ensures your privacy and consent are respected while allowing for the necessary support from trusted individuals. Please make sure both you and the person you're sharing with are registered users of the platform to facilitate this process.
                    </div>
                </div>
            </div>
            <div className="container mt-3">
                <Form>
                    <div className="row mb-3 g-2">
                        <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                            <label>Email of Integrated Account</label>
                        </div>
                        <div className="col-md-2">
                            <input className="form-control" type="text" name="shared_with_email" value={formData.shared_with_email} onChange={handleChange} style={{ height: '55px' }} />
                        </div>
                        <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                            <label>Integration Authorization Code</label>
                        </div>
                        <div className="col-md-2">
                            <input className="form-control" type="text" name="sharing_authorization_code" value={formData.sharing_authorization_code} onChange={handleChange} style={{ height: '55px' }} />
                        </div>
                        <div className="col-md-2 rounded psyd-primary-bg-color d-flex justify-content-center align-items-center">
                            <Button variant="primary" onClick={handleSubmit}>
                                Update Integration Information
                            </Button>
                        </div>
                    </div>
                    <div className="row mb-3 g-2">
                        <div className="col-md-12 rounded psyd-primary-bg-color">
                            <div className='psyd-font-light'>{errorMessage}</div>
                        </div>
                    </div>
                    <div className="row mb-3 g-2">
                    </div>
                </Form>
            </div>
            <div className="container-fluid px-4">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-left">
                        Session Shared with me &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </div>
            </div>
            <div className="container-fluid px-4">
                {messages.length === 0 ? (
                    <div className="col rounded psyd-secondary-bg-color psyd-font text-center">No sessions yet</div>
                ) : (
                    messages.map((record) => (
                        <div className="col-md-12 mb-2 rounded" key={record.therapy_session.history_id}>
                            <div className="mb-0 mt-0 p-0 border border-left border-top border-right border-3 rounded-top">
                                <div className="psyd-primary-bg-color psyd-font-light p-1">
                                    <Button onClick={() => openPDF(record.therapy_session.session_id)}>View this Session</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    Shared By:&nbsp;{(record.user.first_name)} &nbsp;{(record.user.last_name)}&nbsp;
                                    Session Date:&nbsp;{convertUtcToLocal(record.therapy_session.session_start)}&nbsp;&nbsp;
                                    Session Name:&nbsp;{record.therapy_session.session_name.substring(0, 300)}
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>

        </div>
    );
};

export default Share;
