// src/App.js
import React, { useCallback, useState, useEffect } from 'react';
import './css/app.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Messages from './components/chat';
import Home from './components/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Copyright from './components/Copyright';
import ThemeSelection from './components/ThemeSelection';
import About from './components/About';
import Layout from './components/Layout';
import ProcessSubscription from './components/processsubscription';
import Sessions from './components/sessions';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import ProcessLogin from './components/processLogin';
import Process from './components/process';
import Profile from './components/profile';
import AgeGroup from './components/agegroup';
import RelationshipStatus from './components/relationshipstatus';
import ProfessionalStatus from './components/professionalstatus';
import JobCategory from './components/jobcategory';
import PsychologicalConcern from './components/psychologicalconcern';
import Specializations from './components/specializations';
import Plans2 from './components/plans2';
import Consent from './components/consent';
import Terms from './components/terms';
import Personas from './components/personas';
import TherapistProfile from './components/therapistprofile';
import FAQ from './components/faq';
import Resources from './components/resources';
import User from './components/user';
import Share from './components/share';
import CookieConsentModal from './components/cookieconsent';
import { CheckoutForm, Return } from './components/stripepricing';

function App() {
  const [currentTheme, setCurrentTheme] = useState('starryTheme')
  const [theme, setTheme] = useState('starryTheme');
  const [guserSubscriptionPlan, setGuserSubscriptionPlan] = useState('Guest');
  const subscriptionPlans = [{ id: 1, text: 'Free' },{ id: 2, text: 'Basic' },{ id: 3, text: 'Premium' },{ id: 4, text: 'Ultimate' },{ id: 9, text: 'Guest' },{ id: 10, text: 'Family' }];
  const [menuoptions, setMenuOptions] = useState({
    home: { name: 'Home', link: '/' },
    process: { name: 'How it Works', link: '/process' },
    plans: { name: 'Subscription Plans', link: '/plans' },
    specializations: { name: 'Specializations', link: '/specializations' },
    faq: { name: 'FAQ', link: '/faq' },
    resources: { name: 'Resources', link: '/resources' },
    about: { name: 'Meet the Team', link: '/about' },
    login: { name: 'Login', link: '/login' },
  });

  const handleThemeChange = (newTheme) => {
    setCurrentTheme(newTheme);
    setTheme(newTheme);
    Cookie.set('theme', newTheme);  // Store sessionid in cookies
  }
  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
        // If cookies are not accepted, you can block access to the app
        document.body.style.pointerEvents = 'none'; // Disable pointer events (clicks, etc.)
    } else {
        document.body.style.pointerEvents = 'auto'; // Enable pointer events
    }
    let selectedTheme = 'default';
    const themecookieExists = Cookies.get('theme') !== undefined;
    if (themecookieExists) {
      selectedTheme = Cookies.get("theme");
    } else {
      selectedTheme = "default";
    }
    if (selectedTheme === 'default') { import('./css/psyd-calm.css') }
    else if (selectedTheme === 'campfireTheme') { import('./css/psyd-campfire.css') }
    else if (selectedTheme === 'luxuryTheme') { import('./css/psyd-luxury.css') }
    else if (selectedTheme === 'starryTheme') { import('./css/psyd-starry.css') }
    else if (selectedTheme === 'blackTheme') { import('./css/psyd-black.css') }
    else if (selectedTheme === 'whiteTheme') { import('./css/psyd-white.css') }
    else if (selectedTheme === 'aquaTheme') { import('./css/psyd-aqua.css') }
    else if (selectedTheme === 'calmTheme') { import('./css/psyd-calm.css') }

    setSubscriptionPlan();

    const useridcookieExists = Cookies.get('userid') !== undefined;
    if (useridcookieExists) {
      changeMenuOption('login', 'Profile', '/profile');
      changeMenuOption('process', 'My Sessions', '/sessions');

    } else {
      changeMenuOption('login', 'Login', '/login');
      changeMenuOption('process', 'How it Works', '/process');
    }
  }, [theme], [menuoptions]);

  useEffect(() => {
    console.log('current plan is', guserSubscriptionPlan);
  }, [guserSubscriptionPlan]);  // Logs the new state value after it's updated

  const setSubscriptionPlan = () => {
    const usersubscriptioninfoExists = Cookies.get('usersubscriptioninfo') !== undefined;
    let usersubscriptioninfo = {};
    if (usersubscriptioninfoExists) {
      usersubscriptioninfo = JSON.parse(Cookies.get('usersubscriptioninfo'));
      const plan = subscriptionPlans.find(plan => plan.id == usersubscriptioninfo.subscription_plan_id);
      setGuserSubscriptionPlan(plan.text);
    } else { setGuserSubscriptionPlan('Guest'); }
  }

  const changeMenuOption = useCallback((optionKey, newName, newLink) => {
    setMenuOptions(prevOptions => ({
      ...prevOptions,
      [optionKey]: { name: newName, link: newLink }, // Update name and link
    }));
  }, []); // Include dependencies if necessary

  return (

    <div className="App">
      <CookieConsentModal />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout menuoptions={menuoptions} />}>
            <Route index path='/' element={<Home />} />
            <Route path='/messages' element={<Messages guserSubscriptionPlan={guserSubscriptionPlan} />} />
            <Route path='/sessions' element={<Sessions guserSubscriptionPlan={guserSubscriptionPlan} />} />
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/theme-select' element={<ThemeSelection handleThemeChange={handleThemeChange} />} />
            <Route path='/copyright' element={<Copyright />} />
            <Route path='/about' element={<About />} />
            <Route path='/plans' element={<Plans2 />} />
            <Route path='/purchase' element={<CheckoutForm />} />
            <Route path='/processsubscription' element={<ProcessSubscription guserSubscriptionPlan={guserSubscriptionPlan} setSubscriptionPlan = {setSubscriptionPlan} />} />
            <Route path='/processlogin' element={<ProcessLogin changeMenuOption={changeMenuOption} guserSubscriptionPlan={guserSubscriptionPlan} setSubscriptionPlan = {setSubscriptionPlan}/>} />
            <Route path='/process' element={<Process />} />
            <Route path='/profile' element={<Profile changeMenuOption={changeMenuOption} guserSubscriptionPlan={guserSubscriptionPlan} setSubscriptionPlan = {setSubscriptionPlan}/>} />
            <Route path='/agegroup' element={<AgeGroup guserSubscriptionPlan={guserSubscriptionPlan} />} />
            <Route path='/relationshipstatus' element={<RelationshipStatus guserSubscriptionPlan={guserSubscriptionPlan} />} />
            <Route path='/professionalstatus' element={<ProfessionalStatus guserSubscriptionPlan={guserSubscriptionPlan} />} />
            <Route path='/jobcategory' element={<JobCategory guserSubscriptionPlan={guserSubscriptionPlan} />} />
            <Route path='/psychologicalconcern' element={<PsychologicalConcern guserSubscriptionPlan={guserSubscriptionPlan} />} />
            <Route path='/specializations' element={<Specializations />} />
            <Route path='/consent' element={<Consent />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/personas' element={<Personas guserSubscriptionPlan={guserSubscriptionPlan} />} />
            <Route path='/therapistprofile' element={<TherapistProfile guserSubscriptionPlan={guserSubscriptionPlan} />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/resources' element={<Resources />} />
            <Route path='/user' element={<User guserSubscriptionPlan={guserSubscriptionPlan} />} />
            <Route path='/share' element={<Share guserSubscriptionPlan={guserSubscriptionPlan} />} />
            <Route path="/checkout" element={<CheckoutForm />} />
            <Route path="/return" element={<Return guserSubscriptionPlan={guserSubscriptionPlan} setSubscriptionPlan = {setSubscriptionPlan} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
