import React, { useState, useEffect } from 'react';
import { Button, Form } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';



const FAQ = () => {

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>FAQ</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font align-items-center rounded">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                    The FAQ section is designed to provide you with clear and concise answers to common questions about our AI-powered therapy platform. Here, you can find information on how our platform works, how your privacy is protected, and how to get the most out of your therapy sessions. Our goal is to ensure you have a smooth and informative experience, so please don't hesitate to explore the FAQ section or reach out to our support team if you have any further questions.
                    </div>
                </div>
            </div>
            <div className="container mt-3  d-flex justify-content-center align-items-top" style={{ minHeight: '50vh' }}>
                <div className="col">
                    <div class="accordion" id="faqAccordion">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading1">
                                <button class="accordion-button psyd-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                    1. What is an AI-powered therapy platform?
                                </button>
                            </h2>
                            <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#faqAccordion">
                                <div class="accordion-body psyd-font">
                                    An AI-powered therapy platform uses advanced artificial intelligence to support mental health and therapy sessions. It provides personalized guidance, tools, and resources tailored to your emotional and mental well-being. The AI therapist can simulate conversations and offer recommendations, while also integrating with human therapists to enhance the therapeutic experience.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading2">
                                <button class="accordion-button psyd-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                    2. How does the AI therapist work?
                                </button>
                            </h2>
                            <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#faqAccordion">
                                <div class="accordion-body psyd-font">
                                    The AI therapist uses natural language processing and machine learning algorithms to understand your responses and provide helpful insights, advice, and coping strategies based on your emotional state and inputs. It simulates therapeutic conversations and helps you explore your thoughts, feelings, and behaviors in a safe and supportive environment.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading3">
                                <button class="accordion-button psyd-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                    3. Is the AI therapist a substitute for a human therapist?
                                </button>
                            </h2>
                            <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#faqAccordion">
                                <div class="accordion-body psyd-font">
                                    No, the AI therapist is not a replacement for a licensed human therapist. It serves as a supplemental tool to assist you between sessions or as an introduction to therapy. For more serious or complex mental health concerns, it is recommended to consult a licensed therapist.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading7">
                                <button class="accordion-button psyd-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                    4. Can I customize the AI therapist?
                                </button>
                            </h2>
                            <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#faqAccordion">
                                <div class="accordion-body psyd-font">
                                    Yes, you can personalize your experience by choosing a therapist name and profile picture for the AI therapist. This makes the sessions more relatable and helps you build a connection with the virtual assistant. Additionally, you can categorize your therapy sessions by mental health concerns or create custom labels for better organization.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading9">
                                <button class="accordion-button psyd-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                    5. Can the AI therapist help with specific mental health issues, such as anxiety or depression?
                                </button>
                            </h2>
                            <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#faqAccordion">
                                <div class="accordion-body psyd-font">
                                    Yes, the AI therapist can offer guidance, coping strategies, and mental health resources for a variety of concerns such as anxiety, depression, stress, relationship challenges, and more. However, for ongoing or serious mental health issues, it’s important to speak with a licensed therapist to receive comprehensive care.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading6">
                                <button class="accordion-button psyd-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                    6. Will the AI therapist remember previous sessions?
                                </button>
                            </h2>
                            <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#faqAccordion">
                                <div class="accordion-body psyd-font">
                                    Yes, the AI therapist can keep track of your previous sessions and provide continuity in your therapy journey. This allows it to offer more personalized suggestions and follow up on any prior discussions or goals. However, you can also opt to reset your session history for privacy if you prefer.                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading7">
                                <button class="accordion-button psyd-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                    7. Can the AI therapist provide emergency support?
                                </button>
                            </h2>
                            <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#faqAccordion">
                                <div class="accordion-body psyd-font">
                                    The AI therapist is not equipped to handle emergencies. If you’re experiencing a mental health crisis, please contact a licensed mental health professional, a helpline, or go to the nearest emergency room. For users in distress, we provide resources for crisis management and local emergency contacts.                               </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading8">
                                <button class="accordion-button psyd-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                    8. How does the platform ensure accuracy in therapy suggestions?
                                </button>
                            </h2>
                            <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#faqAccordion">
                                <div class="accordion-body psyd-font">
                                    The AI therapist is built using the latest psychological research and machine learning models that have been trained on a wide range of mental health issues. While it can offer valuable advice and coping mechanisms, it’s important to consult a human therapist for a more personalized and in-depth understanding of your mental health concerns.                             </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading9">
                                <button class="accordion-button psyd-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                    9. How is my privacy protected?
                                </button>
                            </h2>
                            <div id="collapse9" class="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#faqAccordion">
                                <div class="accordion-body psyd-font">
                                    Your privacy is our top priority. We use advanced security measures to protect your personal information. All conversations with the AI therapist are confidential and will not be shared with third parties.                          </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="heading10">
                                <button class="accordion-button psyd-font" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    10. Is my data secure?
                                </button>
                            </h2>
                            <div id="collapse10" class="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#faqAccordion">
                                <div class="accordion-body psyd-font">
                                    Yes, we take your privacy and security seriously. All conversations with the AI therapist and human therapists are encrypted and stored securely in compliance with industry standards, such as HIPAA (Health Insurance Portability and Accountability Act). Your personal information is kept confidential and is never shared with third parties without your consent.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    );
}

export default FAQ;
