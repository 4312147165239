// Header.js
import { useState } from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useEffect } from 'react';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';


function Header() {
    const [loggedinuser, setloggedInUser] = useState('');
    useEffect(() => {
        let userinfo = {};
        const userCookie = Cookies.get('userinfo');
        if (userCookie) {
            userinfo = JSON.parse(userCookie);
            setloggedInUser(userinfo.preferred_name);
        } else {
            setloggedInUser('Guest');
        }
    });

    return (
        <div className="container-fluid border psyd-header-background-image">
            <div className="row d-flex flex-column justify-content-center align-items-center psyd-primary-bg-color psyd-font psyd-header-background-text">
                <div className="col d-flex flex-column justify-content-center align-items-center text-center">
                    <h1>Personalized Therapy</h1>
                    <h4>Anyone, Anywhere, Anytime</h4>
                    <h6>Hello, {loggedinuser}</h6>
                    <Link to="/sessions">
                        <Button>Start a Session</Button>
                    </Link>
                </div>
            </div>
        </div>);
}

export default Header
